@import '../../variables.scss';

.containerHeader {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 14fr 1fr;
  height: 50px;

  // Media query for screens smaller than or equal to 568px
  .Image {
    height: auto;
    width: 210px;
  }

  :global {
    @media (max-width: 568px) {
      .gridLayout {
        height: 50px;
      }
    }

    .ant-menu-item {
      display: flex;

      .ant-menu-title-content {
        display: flex;
        justify-content: center;
        align-items: center;
        .StarFilled {
          svg {
            width: 20px;
            height: 20px;
            color: yellow;
          }
        }
      }
    }
  }

  @media (max-width: 568px) {
    height: 160px;
    display: flex;
    flex-direction: column;

    .Image {
      height: auto;
      width: 100%;
    }
  }
}
